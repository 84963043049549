import { memo } from 'react';

type Props = {
  size?: string;
};

const FacebookIcon = ({ size = '20' }: Props) => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill="#FFFFFF"
      d="M18.23,30.4V17.264h4.41l0.659-5.121H18.23V8.875c0-1.482,0.412-2.493,2.537-2.493l2.711-0.001V1.802
      C23.009,1.74,21.4,1.6,19.527,1.6c-3.907,0-6.584,2.386-6.584,6.767v3.775H8.522v5.12h4.421v13.137h5.288V30.4z"
    />
  </svg>
);

export default memo(FacebookIcon);
